.loginPage .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.loginPage .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    border: 1px solid #acacac;
    display: flex;
}
.loginPage .ant-tabs-nav-list {
    width: 100%;
}
.loginPage .ant-tabs-tab {
    display: flex;
    width: 33% !important;
}
.loginPage .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, .loginPage .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 10px;
}
.loginPage .ant-tabs-tab-active {
    border-bottom: 5px solid #2038a2 !important;
}
.loginPage .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: rgba(0, 0, 0, 0.65) !important;
}

@media screen and ( min-width: 640px) {
    div.loginPage {
        width: 60%;
    }
}

@media screen and ( max-width: 640px) {
    div.loginPage {
        width: 100%;
        margin: 0;
    }
    div.loginPage .ant-tabs-nav {
        margin: 0px !important;
    }
    div.loginPage .ant-card-bordered {
        margin: 0px !important;
    }
    p.copyRightText {
        margin-top: 50px;
    }
    span.loginTabSpan {
        display: none;
    }
    .loginTitle {
        display: none;
    }
}