@import "~antd/dist/antd.less";
@import "./theme.less";
@primary-color: #1890ff;
@layout-header-background: #2038a2;
@link-color: rgba(0, 0, 0, 0.65);
@text-color: rgba(0, 0, 0, 0.65);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-breadcrumb-link {
  // background-color: #595959 !important;
}

.form-with-label-wrap {
  .ant-form-item-label {
    overflow: unset;
    line-height: 1.3215em;
    white-space: unset;
  }
}

.wd-image {
  width: 100%;

  .ant-image {
    width: 100%;
  }

  .ant-image-img {
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
  }
}

.plant-table {
  .ant-table-content {
    overflow-x: auto !important;
  }
}

.custom-number-input {
  width: 100%;

  .ant-input-number-input-wrap {
    padding-right: 12px;
  }

  input {
    text-align: right;
  }
}

.farmer {
  .profile-card {
    border-radius: 16px;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
    border-top: 2px solid green;
    padding: 8px 16px;
  }

  .profile-name {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    text-align: center;

    &-outer {
      padding: 16px;
    }
  }

  .profile-list-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);

    &-outer {
      padding: 8px 16px;

      &:last-child {
        .profile-list-item {
          border: none;
        }
      }
    }

    .label {
      font-weight: bold;
      padding: 2px 0;
    }

    .value {
      padding: 2px 0 16px 0;
    }
  }

  .info-card {
    border-radius: 16px;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
    border-top: 2px solid green;
    padding: 16px 16px;
    min-height: 90vh;
  }
}

.support-table {
  .success-row {
    background: rgba(52, 152, 219, 0.2);

    &:hover {
      background: rgba(52, 152, 219, 0.3);

      td {
        background: rgba(52, 152, 219, 0.3) !important;
      }
    }
  }

  .special-td {
    width: 25;
    height: 25;
    border: 2px solid #3498db;
    border-radius: 50%;
    text-align: center;
  }
}

.form-long-label {
  .ant-form-item-label {
    label {
      white-space: pre-wrap;
      height: auto;
    }
  }
}

